*,
*:after,
*:before {
  box-sizing: border-box;
}

html {
  overflow-y: overlay;
  font-size: 12px;
}

body,
html {
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100%;
  font-size: 12px;
  color: #444;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -webkit-user-select: none;
  cursor: default;
  overflow: hidden;
  background-color: #fff;
}

.error-boundary {
  text-align: center;
}

/*make windows scrollbars to be styles like mac */
::-webkit-scrollbar {
  width: 14px;
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 100px;
}

::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 100px;
}

::-webkit-scrollbar-thumb:active {
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-border-radius: 100px;
}

.draggable {
  -webkit-app-region: drag;
}

.no-draggable {
  -webkit-app-region: no-drag;
}

.submitted .edit-button,
.submitted .event-add-to-entry-btn {
  pointer-events: none;
  cursor: not-allowed;
  visibility: hidden;
}

#react-root {
  width: 100%;
  height: 100vh;
  background-color: #fff;
}

.app-window {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.app-window.windows {
  /* border: 1px solid #26649087; */
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 8px 0;
  line-height: 1.4;
}

.menu-icon {
  position: absolute;
  top: 28px;
  left: 15px;
  color: #fff;
  z-index: 4;
  display: none;
}

.content {
  padding: 16px;
}

button,
.button {
  outline: none;
}

.label {
  float: left;
  display: inline-block;
  color: #aaa;
  line-height: 40px;
  padding-left: 14px;
}

.margin-0 {
  margin: 0;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;
}

.flex-1 {
  flex: 1;
  position: relative;
}

.calendar--month {
  height: 135px;
}

.calendar--week {
  min-height: 128px;
}

.calendar--day {
  min-height: 130px;
}

.calendar-slider {
  position: relative;
  display: flex;
  padding-left: 67px;
  padding-right: 15px;
}

.days-of-week {
  display: flex;
  color: #ffffff;
  width: 100%;
  height: 25px;
}

.week-label {
  color: black;
  margin-top: 4px;
}

.days-of-week > div {
  width: 100%;
}

.days-of-week .day-header {
  flex: 0 0 calc(100% / 7);
  text-align: center;
  position: relative;
}

.days-of-week .day-header > span {
  text-transform: capitalize;
}

.calendar .day {
  position: relative;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 125ms ease-in-out;
  flex-direction: column;
  font-size: 14px;
}

.day--unavailable {
  pointer-events: none;
}

.calendar .day > span {
  position: relative;
}

.calendar .day:hover {
  cursor: pointer;
}

.calendar .day:before {
  display: block;
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  top: 45%;
  right: 0;
  bottom: 0;
  left: 50%;
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.75);
  transition: opacity 125ms ease-in-out, transform 125ms ease-in-out;
}

::-webkit-keyframes bounceIn {
  0%,
  100%,
  20%,
  40%,
  50%,
  70% {
    animation-timing-function: ease-in-out;
  }

  0% {
    transform: translate(-50%, -50%) scale3d(0.75, 0.75, 0.75);
  }

  50% {
    transform: translate(-50%, -50%) scale3d(1.05, 1.05, 1.05);
  }

  70% {
    transform: translate(-50%, -50%) scale3d(0.98, 0.98, 0.98);
  }

  100% {
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
}

.calendar .day.selected:before {
  opacity: 0.2;
  border-radius: 50%;
  background-color: #ffffff;
  transform: translate(-50%, -50%) scale(1);
  animation: bounceIn 250ms ease-in-out;
}

.calendar .day.selected {
  font-weight: 600;
}

.calendar .day.different-month {
  color: #bbb;
}

.calendar .day.today:not(.selected) {
  font-weight: 600;
  color: #ffeef3;
}

.status-container--submission {
  display: flex;
  align-items: center;
  color: white;
}

.sync--offline {
  color: red !important;
}

.status-container--sync {
  display: flex;
  align-items: center;
  padding-right: 40px;
  width: 100%;
}

.status-item {
  font-size: 0.8rem;
  text-align: left;
  margin-left: 5px;
}

.status-submit-item {
  margin-left: auto;
}

.progress-bar-daily {
  display: flex;
  height: 15px;
}

.progress-bar-daily.list-view {
  padding: 0;
  margin-right: 14px;
}

.progress-bar {
  text-align: left;
  width: 100%;
  height: 100%;
}

.days-of-week .progress-bar {
  height: 5px;
  position: absolute;
}

.progress-bar .dot {
  min-width: 5px;
  height: 100%;
  border-radius: 1px;
  display: inline-block;
}

.event-list {
  position: relative;
  letter-spacing: 0.4;
  background-color: #ffffff;
  color: #444;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 0 0 3px 3px;
  padding: 5px 0;
}

.event-list-container::-webkit-scrollbar {
  -webkit-border-radius: 0px;
  width: 17px;
}

.event-list-container::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.event-list-container::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px;
}

.event-list-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media (min-width: 960px) {
  .event-list-container {
    width: 350px;
  }
}

.event .event-icon {
  padding-left: 10px;
}

.event .attendees {
  flex: 1 0 100%;
  padding-left: 12px;
  padding-bottom: 10px;
}

.event .attendees .avatar {
  background-size: cover;
  background-position: center center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  vertical-align: middle;
  display: inline-block;
}

.event .attendees .avatar + .avatar {
  margin-left: 4px;
}

.event .attendees .more {
  vertical-align: middle;
  margin-left: 4px;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  border: 1px solid #979797;
  color: #999;
  font-size: 11px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.event-list:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}

.event-list::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.event-list-header {
  margin-top: 12px;
  padding: 4px 12px 0px 12px;
  color: #888888;
  font-family: 'Arial Medium', sans-serif;
  font-size: 12px;
}

.event {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #888888;
  margin: 4px 8px;
  font-family: 'Arial Medium', sans-serif;
  padding: 2px 5px;
  font-size: 0.8rem;
}

.event-content {
  width: 100%;
}

.event-attendees {
  margin: 8px 0;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.event-recipients > span:first-of-type {
  color: black;
  padding-right: 5px;
}

.event-attendees .names {
  color: #888;
  flex: 1;
}

.event-attendees svg {
  margin-left: 4px;
}

.event-attendees span:first-child,
.event-project span:first-child {
  font-weight: bold;
  margin-right: 5px;
}

.event-agenda {
  margin-bottom: 8px;
  overflow: hidden;
  font-size: 1rem;
  font-family: 'Arial', sans-serif;
  word-break: break-word;
}

.event-agenda span {
  color: #444444;
}

.event-agenda table {
  font-size: 0.8rem;
}

.event-project span:not(:first-child) {
  color: #7fc349;
}

.truncated {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: block;
  white-space: nowrap;
  /* forces text to single line */
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-add-to-entry-btn {
  position: absolute;
  right: 5px;
  top: 2px;
}

.event-add-to-entry-btn,
.event-toggle-message-btn {
  display: inline-block;
  border: none;
  margin: 0;
  text-decoration: none;
  background: transparent;
  color: #0072c6;
  font-family: sans-serif;
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0;
}

.truncate-link-default {
  color: #0072c6;
  text-decoration: none;
}

.event-add-to-entry-btn:hover,
.event-toggle-message-btn:hover {
  box-shadow: none;
}

.event-subject {
  font-size: 1.2rem;
  width: 75%;
  color: #0d47a1;
}

.event-message {
  color: #000;
  font-size: 1rem;
  font-family: 'Arial', sans-serif;
  margin-bottom: 8px;
  overflow: hidden;
  word-break: break-word;
}

.event-message table {
  font-size: 1rem;
}

.event.empty {
  text-align: center;
  display: block;
  background: none;
  box-shadow: none;
  padding: 6px;
}

.event .name,
.event .time {
  padding: 0;
}

.event .name {
  flex: 1;
  align-items: center;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #0d47a1;
}

.event .location {
  color: #888;
  font-size: 11px;
  margin-top: 5px;
}

.event .location:empty {
  display: none;
}

.event .time {
  font-size: 0.9rem;
  color: #0d47a1;
}

.event.selected {
  border: 1px solid #0072c6;
}

.event.highlighted {
  border: 1px solid #0072c6;
  background-color: #f5f5f5;
}

email.event.selected .time,
email.event.selected .name {
  color: #0d47a1;
}

.event.used {
  opacity: 0.5;
}

[data-tooltip] {
  position: relative;
}

[data-tooltip]::before {
  content: attr(data-tooltip);
  display: block;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transition: all 250ms ease;
  transform: translate(-50%, 10px);
  left: 50%;
  bottom: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 4px 8px;
  border-radius: 2px;
  font-size: 11px;
}

[data-tooltip]:hover::before {
  transform: translate(-50%, -10px);
  opacity: 1;
  pointer-events: auto;
}

/*auto update*/
.header-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: lightyellow;
  padding: 5px;
  text-align: center;
}

.progress,
.done,
.message {
  display: block;
}

.left-blank-div {
  width: 68px;
  flex: none;
}

.calendar-events-offset {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #ddd;
  z-index: 4;
  width: 100%;
  font-size: 10px;
  font-weight: bold;
  opacity: 0.6;
  border: 1px solid #dddddd;
  line-height: 1;
}

@media (min-width: 960px) {
  .calendar-events-offset {
    margin-right: 350px;
  }
}

.calendar-events-offset .events-link,
.calendar-events-offset .calendar-link {
  color: #0072c6;
  cursor: pointer;
}

.calendar-events-offset .disabled-link {
  cursor: auto;
  color: black;
}

.react-draggable {
  width: 100%;
}

.grouped-events {
  display: flex;
  flex-direction: column;
  margin: 8px 8px 4px 8px;
}

.grouped-events.used {
  opacity: 0.5;
}

.grouped-events .event-header {
  display: flex;
  border: 1px solid #888888;
  z-index: 10;
}

.grouped-events .grouped-event-list {
  margin-left: 42px;
}

.grouped-events .grouped-event-list .grouped-event {
  margin: 8px 0;
}

.grouped-events .event-header .toggle-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  min-width: 36px;
  margin-right: 2px;
}

.grouped-events .event-header .toggle-indicator:hover {
  cursor: pointer;
}

.grouped-events .event-header .grouped-event {
  width: 100%;
  margin: 0;
  padding: 3px 3px 3px 5px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.grouped-events .event-header .grouped-event:hover {
  cursor: pointer;
}

.grouped-events .event-header .grouped-event.event-toggled {
  background-color: #415059;
}

.grouped-events .event-header .grouped-event.event-toggled .name {
  color: #ffffff;
}

.grouped-events .event-header .grouped-event .time {
  margin-left: auto;
}

.grouped-events .event-header .grouped-event.event-toggled .time {
  color: #ffffff;
}

.events-arrow {
  border: 1px solid #888888;
  border-radius: 50%;
  color: #888888;
  font-size: 18px !important;
}

.dialog-width > div > div {
  min-width: 35% !important;
}

.dialog-text {
  padding: 0px 24px 0px !important;
}

.dialog-title > h2 {
  color: #0072c6 !important;
}

.dialog-text > p {
  font-size: 1.12rem !important;
}

@media only screen and (max-width: 576px) {
  .progress-bar {
    width: 100%;
  }

  .calendar-events-offset.visible {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 55%;
  }
}

@media only screen and (max-width: 1194px) and (min-width: 577px) {
  .calendar-events-offset.visible {
    width: 100%;
  }
}

.iframe-container {
  height: calc(100vh - 41px);
  background: #fff url('../src/views/reports/loader.gif') no-repeat 50% 45%;
}
